<template>
  <div class="Retrievaldetailss">
    <Headernav></Headernav>
    <Retrievalnav></Retrievalnav>
    <!-- 主体部分 -->
    <div class="banner">
      <p class="title"><em></em>您当前的位置： 首页 > 书目检索 > 书目列表 > 图书详情</p>
      <div class="topdetail">
        <img :src="detailsdata.imgUrl" alt="" />
        <div class="right">
          <p class="dettitle">{{ detailsdata.title }}</p>
          <p>
            <span>作者： {{ detailsdata.author }}</span> 出版社：
            {{ detailsdata.publish }}
          </p>
          <p>
            <span>ISBN： {{ detailsdata.isbn }}</span> 索书号： {{ detailsdata.callno }}
          </p>
          <p>
            <span>价格： {{ detailsdata.price }}</span> 出版年：
            {{ detailsdata.publishdate }}
          </p>
          <p>
            <span>出版信息：{{ detailsdata.publish }}</span
            >出版地：{{ detailsdata.publishaddress }}
          </p>
          <p>
            <span> 资源类型： {{ detailsdata.doctype }}</span> 页码：
            {{ detailsdata.page }}
          </p>
          <!-- <p><span>主题词： 000</span> 语种： 123</p> -->
        </div>
      </div>
      <div class="contentvalidity">
        <p class="linetop"><em></em> 内容简介 <em></em></p>
      </div>
      <div class="collectionlist">
        <p class="linetop"><em></em> 馆藏列表 <em></em></p>
        <div class="frame">
          <div class="checktab">
            <label>所在馆:</label><span class="active">全部</span>
          </div>
          <div class="checktab">
            <label>馆藏状态:</label>
            <span
              @click="checkstatename(0, '')"
              :class="statenameactive == 0 ? 'active' : ''"
              >全部</span
            >
            <span
              @click="checkstatename(1, '在馆')"
              :class="statenameactive == 1 ? 'active' : ''"
              >在馆</span
            >
            <span
              @click="checkstatename(2, '借出')"
              :class="statenameactive == 2 ? 'active' : ''"
              >借出</span
            >
          </div>
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column label="索书号" width="120">
              <template slot-scope="scope">{{ scope.row.callno }}</template>
            </el-table-column>
            <el-table-column prop="localname" label="馆藏地点">
              <template slot-scope="scope">{{ scope.row.localname }}</template>
            </el-table-column>
            <el-table-column prop="cirname" label="流通类型">
              <template slot-scope="scope">{{ scope.row.cirname }}</template>
            </el-table-column>
            <el-table-column prop="simplename" label="文献所属馆">
              <template slot-scope="scope">{{ scope.row.simplename }}</template>
            </el-table-column>
            <el-table-column prop="barcode" label="条码号">
              <template slot-scope="scope">{{ scope.row.barcode }}</template>
            </el-table-column>
            <el-table-column prop="returnTime" label="归还时间">
              <template slot-scope="scope">{{ scope.row.returnTime }}</template>
            </el-table-column>
            <el-table-column prop="curlib" label="所在馆">
              <template slot-scope="scope">{{ scope.row.curlib }}</template>
            </el-table-column>
            <el-table-column prop="statename" label="流通状态">
              <template slot-scope="scope">{{ scope.row.statename }}</template>
            </el-table-column>
          </el-table>
          <span class="appointment">预约</span>
          <span class="collection">收藏</span>
        </div>
      </div>
    </div>
    <el-backtop :visibility-height="100"></el-backtop>
    <Footer></Footer>
  </div>
</template>
<script>
import Headernav from "../components/headernav.vue";
import Footer from "../components/footer.vue";
import Retrievalnav from "../components/retrievalnav.vue";
import API from "../api/api_borrow";
export default {
  name: "Retrievaldetailss",
  data() {
    return {
      tableData: [],
      detailsdata: "",
      statenameactive: "0",
      statename: "",
    };
  },
  components: {
    Headernav,
    Footer,
    Retrievalnav,
  },
  watch: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    getDetails() {
      API.getDetails({
        rdid: this.$route.query.id,
      }).then((res) => {
        if (res.code == 0) {
          this.detailsdata = res.data;
        }
      });
    },
    checkstatename(index, name) {
      this.statenameactive = index;
      this.statename = name;
      this.getHolding();
    },
    getHolding() {
      API.getHolding({
        bookrecno: this.$route.query.id,
        statename: this.statename,
      }).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
  },
  created() {
    this.getDetails();
    this.getHolding();
  },
};
</script>
<style scope>
.Retrievaldetailss {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  
}
.Retrievaldetailss .banner {
  width: 1200px;
  min-height: 700px;
  margin: 0 auto;
}
.Retrievaldetailss .banner .title {
  font-size: 14px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #eee;
}
.Retrievaldetailss .banner .title em {
  display: inline-block;
  width: 3px;
  height: 21px;
  background-color: #ff7701;
  vertical-align: middle;
  margin: 0px 10px 0px 5px;
}
.Retrievaldetailss .banner .topdetail {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 10px 0px;
}
.Retrievaldetailss .banner .topdetail img {
  width: 150px;
  height: 150px;
  margin-right: 40px;
}
.Retrievaldetailss .banner .topdetail .right {
  display: inline-block;
}
.Retrievaldetailss .banner .topdetail .right p {
  color: #000;
  margin: 10px 0px;
  font-size: 14px;
}

.Retrievaldetailss .banner .topdetail .right .dettitle {
  color: #ff0000;
  font-size: 16px;
  margin-top: 0px;
}
.Retrievaldetailss .banner .topdetail .right p span {
  display: inline-block;
  width: 300px;
}
.Retrievaldetailss .banner .contentvalidity {
  width: 100%;
  min-height: 300px;
  border-bottom: 1px solid #eee;
}
.Retrievaldetailss .banner .linetop {
  color: #ac7345;
  font-size: 16px;
  text-align: center;
}
.Retrievaldetailss .banner .linetop em {
  display: inline-block;
  width: 120px;
  height: 1px;
  background-color: #ac7345;
  vertical-align: middle;
  margin: 0px 10px;
}
.Retrievaldetailss .banner .collectionlist .frame {
  width: 100%;
  min-height: 250px;
  border: 1px solid #eee;
  margin-bottom: 100px;
  position: relative;
}
.Retrievaldetailss .banner .collectionlist .frame .checktab {
  color: #000;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 15px 10px;
  border-bottom: 1px solid #eee;
}
.Retrievaldetailss .banner .collectionlist .frame .checktab label {
  margin-right: 40px;
}
.Retrievaldetailss .banner .collectionlist .frame .checktab .active {
  background-color: #ff7701;
  color: #ffffff;
}
.Retrievaldetailss .banner .collectionlist .frame .checktab span {
  display: inline-block;
  width: 60px;
  text-align: center;
  color: #000;
  border-radius: 5px;
  margin-right: 20px;
}
.Retrievaldetailss .banner .collectionlist .frame .appointment {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  background-color: #ae703e;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  right: 120px;
  top: -40px;
}
.Retrievaldetailss .banner .collectionlist .frame .collection {
  display: inline-block;
  width: 80px;
  height: 30px;
  line-height: 30px;
  background-color: #ff7701;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  right: 20px;
  top: -40px;
}
</style>
