import * as API from '.'
export default {
    // 获取历史借阅列表
    getHistoryLoanList: params => {
        return API.POST('api/user/getHistoryLoanList', params)
    },
    //收藏列表
    getcollectlist: () => {
        return API.POST('api/collect/list')
    },
    //搜索
    getelksearch: params => {
        return API.GET('api/elk/search', params)
    },
    //图书详情
    getDetails: params => {
        return API.GET('api/elk/getDetails', params)
    },
    //删除收藏
    getcollectremove: params => {
        return API.POST('api/collect/remove', params)
    },
    //获取图书馆藏信息
    getHolding: params => {
        return API.GET('api/opac/getHolding', params)
    },
    //点击收藏
    getcollectadd: params => {
        return API.POST('api/collect/add', params)
    },
    //点击预约
    getregisterreserve: params => {
        return API.GET('api/opac/registerreserve', params)
    },
    //预约明细列表
    getsearchreslist: params => {
        return API.GET('api/opac/searchreslist', params)
    },
}